import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PaginatorConfig, TableConfig } from '@intersystems/table';
import { IrisSettingObject } from 'admin-api';
import { TableEnhancedIdService } from 'src/app/core/table-enhanced-id.service';
import { SharedUtils } from 'src/app/shared/shared-utils';

@Component({
  selector: 'app-irissettings-table',
  templateUrl: './irissettings-table.component.html',
  styleUrls: ['./irissettings-table.component.scss'],
})
export class IrissettingsTableComponent {
  @Input() irissettings!: IrisSettingObject[];

  @Output() setSetting = new EventEmitter<IrisSettingObject>();

  tableConfig: TableConfig = {
    key: 'irissettings-table',
    header: {
      title: 'Iris Settings',
    },
    noDataMessage: 'No settings available.',
    useSearch: true,
    searchConfig: {
      placeholderLabel: 'Search',
    },
    cssTRClassFromRow: TableEnhancedIdService.setTableRowIdColumn('name'),
    columns: [
      SharedUtils.getTableColumn('name'),
      SharedUtils.getTableColumn('value'),
      {
        id: 'actions',
        key: 'actions',
        title: 'Actions',
        cellDisplay: {
          preset: 'actionsIcons',
          actionsIcons: {
            iconsOrder: ['edit'],
            edit: {
              callback: (_event, item: IrisSettingObject) => this.setSetting.emit(item),
            },
          },
        },
      },
    ],
  };

  paginatorConfig: PaginatorConfig = {
    pageSize: 30,
    pageSizeOptions: [10, 30, 50],
  };
}
