import { Component, Input, OnChanges } from '@angular/core';
import { SharedService } from 'src/app/shared/services/shared.service';
import { IrisSettingObject, IrisSettingsService } from 'admin-api';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '@intersystems/confirmation-dialog';
import { Observable } from 'rxjs';
import { EditIrissettingsDialogComponent } from './dialogs/edit/edit-irissettings-dialog.component';

@Component({
  selector: 'app-irissettings-page',
  templateUrl: './irissettings-page.component.html',
  styleUrls: ['./irissettings-page.component.scss'],
})
export class IrissettingsPageComponent implements OnChanges {
  constructor(
    private irissettingsService: IrisSettingsService,
    private dialog: MatDialog,
    private sharedService: SharedService,
  ) {}

  @Input() tenant!: string;
  @Input() deployment!: string;

  irissettings$: Observable<any>;

  ngOnChanges() {
    this.irissettings$ = this.sharedService.refresh$.pipe(
      switchMap(() => this.irissettingsService.getIrisSettings(this.tenant, this.deployment)),
      map(settingsObject => Object.keys(settingsObject).map(key => ({ name: key, value: settingsObject[key] }))),
    );
  }

  setSetting(setting: IrisSettingObject): void {
    const dialogRef = this.dialog.open(EditIrissettingsDialogComponent, {
      data: setting,
    });

    dialogRef
      .afterClosed()
      .pipe(
        filter(result => result),
        switchMap(setting =>
          this.irissettingsService.setIrisSetting(this.tenant, this.deployment, setting.name, setting),
        ),
        tap(() => this.sharedService.showSuccess('Setting sucessfully changed')),
        tap(() => this.sharedService.emitRefresh()),
        this.sharedService.catchError(),
      )
      .subscribe();
  }

  openBody(body: string): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: 'Signal Body',
      primary: body,
      buttons: {
        primary: {
          text: 'OK',
        },
      },
    };
    dialogConfig.panelClass = 'fr-layout-wrapper-mat-dialog-panel';

    // Use the open() API to instantiate the modal
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
  }
}
