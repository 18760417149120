import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FDN, FormButton } from '@intersystems/isc-form';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-edit-irissettings-dialog',
  templateUrl: './edit-irissettings-dialog.component.html',
})
export class EditIrissettingsDialogComponent {
  FDN: FDN = {
    name: 'Edit IRIS settings',
    validateOn: 'change',
    sectionLayout: { showSectionHeaders: false },
    sections: [
      {
        fields: [
          {
            id: 'name',
            key: 'name',
            type: 'input',
            viewOnly: true,
            templateOptions: {
              label: 'Name',
            },
            data: {},
          },
          {
            id: 'value',
            key: 'value',
            type: 'input',
            templateOptions: {
              required: true,
              label: 'Value',
            },
          },
        ],
      },
    ],
  };

  buttons: FormButton[] = [
    {
      id: 'btn-save',
      text: 'Save',
      buttonClass: 'primary',
      type: 'submit',
      disabledIfFormInvalid: true,
      disabledIfFormPristine: true,
      callback: () => {
        this.dialogRef.close(this.data);
      },
    },
    {
      id: 'btn-cancel',
      text: 'Cancel',
      buttonClass: 'secondary',
      type: 'reset',
      callback: () => this.dialogRef.close(false),
    },
  ];

  constructor(
    public dialogRef: MatDialogRef<EditIrissettingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
  ) {}
}
