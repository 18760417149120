<span *ngIf="deployment?.status == 'DELETED'; else elseBlock"> </span>
<ng-template #elseBlock>
  <span *ngIf="deployment?.status != 'DELETED'">
    <fr-table [config]="InstanceTableConfig" [data]="deployment?.instance_status_list" Id="instances-table">
      <div class="custom-table-header-space" table-header-space></div>

      <div class="table-sub-header" above-table-body-space>
        <app-loading-spinner *ngIf="!deployment?.instance_status_list"></app-loading-spinner>
      </div>
    </fr-table>
  </span>
</ng-template>

<app-section header="Deployment details for {{ deployment.deploymentname }}">
  <mat-tab-group animationDuration="0ms" (selectedIndexChange)="onIndexChange($event)">
    <mat-tab id="tab-deployment" label="Deployment">
      <ng-template mat-tab-label><span id="tab-admin-deployment">Deployment</span></ng-template>
      <fr-table *ngIf="deployment" [config]="tableConfig" [data]="deploymentData" Id="deployment-table"> </fr-table>
    </mat-tab>
    <mat-tab id="tab-resources" label="Resources">
      <ng-template mat-tab-label><span id="tab-admin-resources">Resources</span></ng-template>
      <fr-table *ngIf="deployment.resource_list" [config]="tableConfig" [data]="resourcesListData" Id="resources-table">
      </fr-table>
    </mat-tab>
    <mat-tab id="tab-settings" label="Settings" *ngIf="deployment?.deploymenttype == 'hcc'">
      <ng-template mat-tab-label><span id="tab-admin-resources">Settings</span></ng-template>
      <app-irissettings-page
        *ngIf="deployment"
        [deployment]="deployment.deploymentid"
        [tenant]="deployment.tenantid"
      ></app-irissettings-page>
    </mat-tab>
    <mat-tab id="tab-close" label="Close">
      <ng-template mat-tab-label><span id="tab-admin-close">Close</span></ng-template>
    </mat-tab>
  </mat-tab-group>
</app-section>
